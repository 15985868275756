.topbar {
    display: flex;
    width: '10px';
}
.row {
    flex-shrink: 1;
}
.btn-main {
    font-size: 14px;
    padding: 6px 20px;
}
.menu {
    display: flex;
}